import React from "react";
import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
import Cta from "../../components/cta/Cta";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar2 from "../../components/layout/header/Navbar2";
import Layout from "../../components/layout/Layout";
import Subsribe from "../../components/newsletter/Subsribe";
import Price from "../../components/prices/Price";
import Testimonios from "../../components/prices/Testimonios"
import Promo from "../../components/promo/Promo";
import Screenshot from "../../components/screenshot/Screenshot";
import Team from "../../components/team/Team";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import HeroSeven from "./HeroSeven";
import PromoAgenteInmobiliario from "../../components/promo/PromoAgenteInmobiliario";
import PromoAgenteInmobiliario2 from "../../components/promo/PromoAgenteInmobiliario2";
import Price2 from "../../components/prices/Price2";

export default function HomeSeven() {
  return (
    <Layout>
      <Navbar2 darkBg />
      <HeroSeven />
      <Promo />
      <PromoAgenteInmobiliario></PromoAgenteInmobiliario>
      {/* <FeatureImgTwo />
      <FeatureImgThree /> */}
      <Price2></Price2>
      {/* <Price hasBg /> */}
      <Testimonios hasBg></Testimonios>
      {/* <TestimonialTwo />
      <Screenshot hasBg /> */}
      {/* <Cta /> */}
      {/* <LatestNewsOne bgColor="gray-light-bg" light /> */}
      <PromoAgenteInmobiliario2></PromoAgenteInmobiliario2>
      <Team />
      {/* <Contact bgColor="gray-light-bg" /> */}
      {/* <BrandCarousel />*/}
      {/* <Subsribe />  */}
      <Footer space />
    </Layout>
  );
}
