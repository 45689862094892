import React from "react";
import { Link } from "react-router-dom";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Guias gratuitas 2025</h2>
                <p className="lead">
                  Recursos diseñados para ayudarte a tomar las mejores
                  decisiones en el mundo inmobiliario. Descarga gratis y empieza
                  hoy mismo a acercarte a tu próximo objetivo.
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <Link to="/blog-details">
                  <img
                    src="assets/img/guias/tendencias-valencia-2024.png"
                    height={120}
                    width={100}
                    alt="blog"
                  />
                </Link>
                <h5>
                  5 tip para revalorizar tu propiedad en mas de 5000 Dolares
                </h5>
                <div class="download-btn">
                  <a class="btn outline-btn" href="/">
                    Proximamente
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <Link to="/blog-details">
                  <img
                    src="assets/img/guias/tendencias-valencia-2024.png"
                    height={120}
                    width={100}
                    alt="blog"
                  />
                </Link>
                <h5>
                  5 tip para revalorizar tu propiedad en mas de 5000 Dolares
                </h5>
                <div class="download-btn">
                  <a class="btn outline-btn" href="/">
                    Descargar Gratis
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <Link to="/blog-details">
                  <img
                    src="assets/img/guias/tendencias-valencia-2024.png"
                    height={120}
                    width={100}
                    alt="blog"
                  />
                </Link>
                <h5>
                  5 tip para revalorizar tu propiedad en mas de 5000 Dolares
                </h5>
                <div class="download-btn">
                  <a class="btn outline-btn" href="/">
                    Descargar Gratis
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
