import React, { useState } from "react";
import ResponsiveDialog from "../../components/modal/ResponsiveDialog";

export default function PromoAgenteInmobiliario() {
  const [isOpen, setOpen] = useState(false);
  const [textTitle, setTextTitle] = useState("");
  const [textButton, setTextButton] = useState();
  const handleOpenValoracion = () => {
    setTextTitle("Solicitar Valoracion");
    setTextButton("Quiero mi valoracion");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <section
        id="valorar"
        className="ptb-100 background-img"
        style={{
          background:
            "url('assets/img/video-bg.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2 className="text-white">
                  Conocé el Mejor PRECIO DE VENTA de tu Propiedad
                </h2>
                <h4 className="text-white">
                  Que te Permita Vender en{" "}
                  <span style={{ color: "#FE9B40" }}>90 dias</span> o antes a
                  través de un Informe de Tasación Profesional{" "}
                  <span style={{ color: "#FE9B40" }}>SIN COSTO 🤩</span>{" "}
                </h4>
                <div class="download-btn">
                  <a
                    class="btn google-play-btn" href="#valorar"
                    onClick={() => handleOpenValoracion(true)}
                  >
                    <span class="ti-home"></span> Solicitar valoracion
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ResponsiveDialog
        open={isOpen}
        handleClose={handleClose}
        titleButton={textButton}
        title={textTitle}
        subTitle={null}
      />
    </>
  );
}

{
  /* <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="mt-4 text-center">
                <div className="col-md-6">
                  <div className="about-content-left">
                    <div className="single-team-member position-relative">
                      <div className="team-image">
                        <img
                          src="assets/img/team-4.jpg"
                          alt="Team Members"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <h2 className="text-white">Damian Frieri</h2>
                      <h4 className="text-yellow">Ingeniero Inmobiliario</h4>
                    </div>
                  </div>
                </div>
                <div className="about-content-right section-heading">
                  <div
                    className="row equal"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gridTemplateRows: "1fr",
                      gridColumnGap: "25px",
                      gridRowGap: "0px",
                    }}
                  >
                    <img
                      loading="lazy"
                      decoding="async"
                      width="385"
                      height="135"
                      src="assets/img/logos/CIA-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                    <img
                      loading="lazy"
                      width="385"
                      height="135"
                      src="assets/img/logos/CUCICBA-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                    <img
                      loading="lazy"
                      width="385"
                      height="135"
                      src="assets/img/logos/ICI-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                    <img
                      loading="lazy"
                      width="385"
                      height="135"
                      src="assets/img/logos/LCNI-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                  </div>
                  <h5 className="mt-4 text-white">
                    En la inmobiliaria estamos comprometidos en vender tu
                    propiedad en 2 meses y al mejor precio de mercado. Para
                    lograrlo utilizamos el "Método por Etapas" que fue creado
                    con este objetivo.
                  </h5>
                  <h5 className="mt-4 text-white">
                    Ademas aplicamos de Marketing Digital, Inteligencia
                    Artificial y Neuroventas equilibrando la innovación
                    tecnológica con las relaciones personales, para cumplir
                    sueños inmobiliarios.
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId="9No-FiEInLA"
            onClose={() => setOpen(false)}
          />
       </div> */
}
