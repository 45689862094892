import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

export default function Testimonios({ hasBg }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    Testimonios <br />
                    Clientes satisfechos 🤩
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-lg-4 col-md">
                <div
                  className="card text-center single-pricing-pack p-5"
                  style={{
                    background:
                      "url('assets/img/hero-bg-1.jpg') no-repeat center center / cover",
                  }}
                >
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <div className="video-promo-content mt-4 text-center ">
                          <h5 className="">Testimonio</h5>
                          <h5 className="">Daniel</h5>
                          <h5 className="">Caso de Exito</h5>
                          <Link
                            to="#"
                            className="popup-youtube video-play-icon d-inline-block"
                          >
                            <span
                              className="ti-control-play"
                              onClick={() => setOpen(true)}
                            ></span>
                          </Link>
                          <h5 className="mt-4 text-white"></h5>
                        </div>
                      </div>
                    </div>
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="9No-FiEInLA"
                      onClose={() => setOpen(false)}
                    />
                  </div>
                  {/* </section> */}
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div
                  className="card text-center single-pricing-pack p-5"
                  style={{
                    background:
                      "url('assets/img/hero-bg-1.jpg') no-repeat center center / cover",
                  }}
                >
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <div className="video-promo-content mt-4 text-center ">
                          <h5 className="">Testimonio</h5>
                          <h5 className="">Daniel</h5>
                          <h5 className="">Caso de Exito</h5>
                          <Link
                            to="#"
                            className="popup-youtube video-play-icon d-inline-block"
                          >
                            <span
                              className="ti-control-play"
                              onClick={() => setOpen(true)}
                            ></span>
                          </Link>
                          <h5 className="mt-4 text-white"></h5>
                        </div>
                      </div>
                    </div>
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="9No-FiEInLA"
                      onClose={() => setOpen(false)}
                    />
                  </div>
                  {/* </section> */}
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div
                  className="card text-center single-pricing-pack p-5"
                  style={{
                    background:
                      "url('assets/img/hero-bg-1.jpg') no-repeat center center / cover",
                  }}
                >
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <div className="video-promo-content mt-4 text-center ">
                          <h5 className="">Testimonio</h5>
                          <h5 className="">Daniel</h5>
                          <h5 className="">Caso de Exito</h5>
                          <Link
                            to="#"
                            className="popup-youtube video-play-icon d-inline-block"
                          >
                            <span
                              className="ti-control-play"
                              onClick={() => setOpen(true)}
                            ></span>
                          </Link>
                          <h5 className="mt-4 text-white"></h5>
                        </div>
                      </div>
                    </div>
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="9No-FiEInLA"
                      onClose={() => setOpen(false)}
                    />
                  </div>
                  {/* </section> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
