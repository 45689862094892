import { useState } from "react";
import { Link } from "react-router-dom";
import ResponsiveDialog from "../../components/modal/ResponsiveDialog";

export default function HeroSeven() {
  const [open, setOpen] = useState(false);
  const [textTitle, setTextTitle] = useState("");
  const [textButton, setTextButton] = useState();

  const handleOpenValoracion = () => {
    setTextTitle("Solicitar Valoracion");
    setTextButton("Quiero mi valoracion");
    setOpen(true);
  };

  const handleOpenMetodo = () => {
    setTextTitle("Descubri nuestro Metodo FSELL-PRO");
    setTextButton("Descubrir");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/fondos/Fondo1-PuertoMadero.avif')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between pt-100">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  ¡Cumplimos tu Sueño Inmobiliario!
                </h2>
                <p className="lead">
                  Gracias a mi métodología innovadora, F-SELLPPRO combinamos una
                  estrategia de marketing digital avanzado y neuroventas para
                  vender tu propiedad en menos tiempo y al mejor valor.
                  {/* Acelera las ventas de pisos, desarrollos y solares con estrategias hechas 100% para bienes raíces con el mejor equipo y desarrollo tecnológico, vendemos tu propiedad en menos tiempo y al mejor valor */}
                </p>

                <div className="download-btn">
                  <Link
                    onClick={() => handleOpenValoracion(true)}
                    className="btn google-play-btn mr-3"
                  >
                    <span className="ti-home"></span> Solicitar valoracion
                  </Link>
                  <Link
                    onClick={() => handleOpenMetodo(true)}
                    className="btn app-store-btn"
                  >
                    <span
                    // className="ti-apple"
                    ></span>{" "}
                    Conocer F-SELLPPRO
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/damian/PerfilModif3.png"
                  alt="download"
                  className="img-fluid"
                  style={{
                    transition:
                      "background 0.3s, border-radius 0.3s, opacity 0.3s",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ResponsiveDialog
          open={open}
          handleClose={handleClose}
          titleButton={textButton}
          title={textTitle}
          subTitle={null}
        />
      </section>
    </>
  );
}
