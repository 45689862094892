import React from "react";

export default function Team({ hasTitle, bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Nuestro equipo
                  </span>
                ) : (
                  ""
                )}
                <h2>Los integrantes que cumplirán tu sueño inmobiliario</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/team/team-damian.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Damian Frieri</h5>
                  <h6>Ingeniero IT</h6>
                  <h6>Especialista en Proptech</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/profile.php?id=100078127983364">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://instagram.com/damianfrieri">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://gitlab.com/damianfr">
                        <span className="ti-github"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/team/team-nico.jpg"
                    alt="Nicolas Marquez"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Nicolas Marquez</h5>
                  <h6>Asesor Inmobiliario Experto </h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/profile.php?id=61553286433192">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li> */}
                    <li className="list-inline-item">
                      <a href="https://instagram.com/nicolasmarquez.ok">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/team/team-pablo.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Pablo Turco</h5>
                  <h6>Asesor Experto</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-facebook"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/team/team-gaston.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Gaston Masucco</h5>
                  <h6>Disenador Senior</h6>
                  <h6>Experto Audivisual</h6>
                  <h6>Lider de Marketing</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://instagram.com/keep_moving.arg">
                        <span className="ti-instagram"></span>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#/">
                        <span className="ti-dribbble"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6"> */}
              
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
