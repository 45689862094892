import React, { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import axios from "axios";
import Select from "react-select";
import Stack from "@mui/material/Stack";
import emailjs from "emailjs-com";
import { Box } from "@mui/material";

const countryList = [
  // América del Norte
  {
    value: "US",
    label: "United States",
    flagUrl: "flags/1x1/us.svg",
    phoneCode: "+1",
  },
  {
    value: "CA",
    label: "Canada",
    flagUrl: "flags/1x1/ca.svg",
    phoneCode: "+1",
  },
  {
    value: "MX",
    label: "Mexico",
    flagUrl: "flags/1x1/mx.svg",
    phoneCode: "+52",
  },

  // América del Sur
  {
    value: "BR",
    label: "Brazil",
    flagUrl: "flags/1x1/br.svg",
    phoneCode: "+55",
  },
  {
    value: "AR",
    label: "Argentina",
    flagUrl: "flags/1x1/ar.svg",
    phoneCode: "+54",
  },
  {
    value: "CL",
    label: "Chile",
    flagUrl: "flags/1x1/cl.svg",
    phoneCode: "+56",
  },
  {
    value: "CO",
    label: "Colombia",
    flagUrl: "flags/1x1/co.svg",
    phoneCode: "+57",
  },
  { value: "PE", label: "Peru", flagUrl: "flags/1x1/pe.svg", phoneCode: "+51" },
  {
    value: "VE",
    label: "Venezuela",
    flagUrl: "flags/1x1/ve.svg",
    phoneCode: "+58",
  },

  // Europa
  {
    value: "GB",
    label: "United Kingdom",
    flagUrl: "flags/1x1/gb.svg",
    phoneCode: "+44",
  },
  {
    value: "FR",
    label: "France",
    flagUrl: "flags/1x1/fr.svg",
    phoneCode: "+33",
  },
  {
    value: "DE",
    label: "Germany",
    flagUrl: "flags/1x1/de.svg",
    phoneCode: "+49",
  },
  {
    value: "ES",
    label: "Spain",
    flagUrl: "flags/1x1/es.svg",
    phoneCode: "+34",
  },
  {
    value: "IT",
    label: "Italy",
    flagUrl: "flags/1x1/it.svg",
    phoneCode: "+39",
  },
  {
    value: "NL",
    label: "Netherlands",
    flagUrl: "flags/1x1/nl.svg",
    phoneCode: "+31",
  },
  {
    value: "BE",
    label: "Belgium",
    flagUrl: "flags/1x1/be.svg",
    phoneCode: "+32",
  },
  {
    value: "CH",
    label: "Switzerland",
    flagUrl: "flags/1x1/ch.svg",
    phoneCode: "+41",
  },
  {
    value: "SE",
    label: "Sweden",
    flagUrl: "flags/1x1/se.svg",
    phoneCode: "+46",
  },
  {
    value: "NO",
    label: "Norway",
    flagUrl: "flags/1x1/no.svg",
    phoneCode: "+47",
  },
  {
    value: "FI",
    label: "Finland",
    flagUrl: "flags/1x1/fi.svg",
    phoneCode: "+358",
  },
  {
    value: "DK",
    label: "Denmark",
    flagUrl: "flags/1x1/dk.svg",
    phoneCode: "+45",
  },
  {
    value: "PL",
    label: "Poland",
    flagUrl: "flags/1x1/pl.svg",
    phoneCode: "+48",
  },
  {
    value: "PT",
    label: "Portugal",
    flagUrl: "flags/1x1/pt.svg",
    phoneCode: "+351",
  },
  {
    value: "IE",
    label: "Ireland",
    flagUrl: "flags/1x1/ie.svg",
    phoneCode: "+353",
  },
  {
    value: "GR",
    label: "Greece",
    flagUrl: "flags/1x1/gr.svg",
    phoneCode: "+30",
  },
  {
    value: "AT",
    label: "Austria",
    flagUrl: "flags/1x1/at.svg",
    phoneCode: "+43",
  },
  {
    value: "HU",
    label: "Hungary",
    flagUrl: "flags/1x1/hu.svg",
    phoneCode: "+36",
  },
  {
    value: "CZ",
    label: "Czech Republic",
    flagUrl: "flags/1x1/cz.svg",
    phoneCode: "+420",
  },
  {
    value: "RO",
    label: "Romania",
    flagUrl: "flags/1x1/ro.svg",
    phoneCode: "+40",
  },
  {
    value: "BG",
    label: "Bulgaria",
    flagUrl: "flags/1x1/bg.svg",
    phoneCode: "+359",
  },
  {
    value: "HR",
    label: "Croatia",
    flagUrl: "flags/1x1/hr.svg",
    phoneCode: "+385",
  },
  {
    value: "SI",
    label: "Slovenia",
    flagUrl: "flags/1x1/si.svg",
    phoneCode: "+386",
  },
  {
    value: "SK",
    label: "Slovakia",
    flagUrl: "flags/1x1/sk.svg",
    phoneCode: "+421",
  },
  // Add more countries as needed
];

const customStyles = {
  control: (provided) => ({
    // class attribute : class=" css-i32vvf-control"
    ...provided,
    height: "50px",
  }),
};

const validationSchema = Yup.object({
  name: Yup.string().required("El nombre es obligatorio"),
  email: Yup.string()
    .email("Email inválido")
    .required("El email es obligatorio"),
  phone: Yup.string()
    .matches(/^\+?[1-9]\d{7,14}$/, "Teléfono no válido o incompleto")
    .required("El número de teléfono es requerido"),
});

const ResponsiveDialog = ({
  open,
  handleClose,
  titleButton,
  title,
  subTitle,
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const successMessage = "Tus datos fueron enviados correctamente.";
  const successMessage2 =
    "Un especialista inmobiliario se pondrá en contacto contigo . Gracias por elegirnos";

  const handleSubmitMail = (values) => {
    const formData = {
      to_name: values.name,
      from_name: values.email,
      message: values.phone,
    };

    try {
      emailjs
      .send(
        `${process.env.REACT_APP_EMAILJS_SERVICE}`,
        `${process.env.REACT_APP_EMAILJS_TEMPLATE}`,
        formData,
        `${process.env.REACT_APP_EMAILJS_USER_ID}`,
        {
          publicKey: `${process.env.REACT_APP_EMAILJS_USER_ID}`,
        }
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessMessage(true);
        },
        (error) => {
          setShowSuccessMessage(false);
          console.log(error.text);
        }
      );
    } catch (error) {
      console.log("Error en envio de mail",error)
    }

    try {
      handledWhatsApp(values)
    } catch (error) {
      console.log("Error en envio de WS",error)
    }

  };

  const handledWhatsApp = async (values) => {
    // Handle form submission
    console.log("values", values);
    console.log(`Country: ${values?.name}, Phone: ${values.phone}`);

    const endpoint = `${process.env.REACT_APP_WS_BOT_URL_CONTACT}`;
   
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: values.name,
          number: values.phone,
          email: values.email,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // const data = await response.json();
      // console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState("");
  const [countryOptions, setCountryOptions] = useState(countryList);
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    const newPhone = selectedOption ? `${selectedOption.phoneCode}` : "";
    setPhone(newPhone);
  };
  const formatOptionLabel = ({ value, flagUrl }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={flagUrl}
        alt=""
        style={{ width: 30, height: 20, marginRight: 5, marginLeft: 5 }}
      />
      {value}
    </div>
  );

  useEffect(() => {
    // Fetch the user's country based on their IP address
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const userCountry = response.data.country_code;
        const callingCode = response.data.country_calling_code;
        const selectedCountry = countryOptions.find(
          (country) => country.value === userCountry
        );
        if (selectedCountry) {
          setCountry(selectedCountry);
          const newPhone = callingCode + "";
          setPhone(newPhone);
        }
      } catch (error) {
        console.error("Error fetching the user country", error);
      }
    };

    fetchUserCountry();
  }, [countryOptions]);

  const form = useRef();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContentText>
        {showSuccessMessage && (
          <Box alignItems="center" justifyContent="center" padding="20px">
            <p className="text-success" style={{ fontSize: "1.5rem" }}>
              {successMessage}
            </p>
            <p className="text-success" style={{ fontSize: "1.5rem" }}>
              {successMessage2}
            </p>
          </Box>
        )}
        {!showSuccessMessage && (
          <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg">
            <div className="sign-up-form-header text-center mb-4">
              <h4 className="mb-0">{title ? title : "Mas informacion"}</h4>
              <p>{subTitle ? subTitle : "Respondemos de inmediato"}</p>
            </div>
            <div className="message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <Formik
              initialValues={{ name: "", email: "", phone: phone }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmitMail(values)}
            >
              {({ isSubmitting, values, handleChange, errors }) => (
                <Form
                  ref={form}
                  noValidate
                  id="getDataForm"
                  className="sign-up-form"
                >
                  <div className="form-group input-group mb-4">
                    <TextField
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Ingresa tu nombre"
                      required
                      value={values?.name}
                      onChange={handleChange}
                      helperText={errors?.name}
                      error={errors?.name?.length > 0}
                    />
                  </div>
                  <div className="form-group input-group mb-4">
                    <TextField
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Ingresa tu email"
                      required
                      value={values?.email}
                      onChange={handleChange}
                      helperText={errors?.email}
                      error={errors?.email?.length > 0}
                    />
                  </div>
                  <Stack>
                    <label>Teléfono</label>
                    <Stack
                      direction="row"
                      spacing={1}
                      mb={3}
                      alignItems={"center"}
                    >
                      <div style={{ width: "160px" }}>
                        <Select
                          value={country}
                          onChange={handleCountryChange}
                          options={countryOptions}
                          formatOptionLabel={formatOptionLabel}
                          placeholder={"Pais"}
                          styles={customStyles}
                        />
                      </div>
                      <div className="form-group input-group">
                        <TextField
                          type="tel"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          className="form-control"
                          helperText={errors?.phone}
                          error={errors?.phone?.length > 0}
                          inputProps={{ maxLength: 15 }}
                        />
                      </div>
                    </Stack>
                  </Stack>
                  <div className="form-group mt-4">
                    <button
                      type="submit"
                      className="btn solid-btn btn-block"
                      disabled={isSubmitting}
                    >
                      {titleButton ? titleButton : "Enviar"}
                    </button>
                  </div>
                  <div className="form-check d-row align-items-center text-center">
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Tu privacidad es importante.
                  </label>
                  <h6 style={{ fontSize: "10px" }}>
                    Al enviar tus datos, aceptas nuestra política de protección
                    de datos y consientes que te contactemos
                  </h6>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </DialogContentText>
       <DialogActions>
         <Button onClick={handleClose}>Cerrar</Button>
       </DialogActions>
     </Dialog>
  );
};

export default ResponsiveDialog;
