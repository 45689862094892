import React, { useState } from "react";
import ResponsiveDialog from "../modal/ResponsiveDialog";

export default function Price2({ hasBg }) {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                  ¿Porque nuestros clientes nos eligen? <br />
                    {/* Somos los mejores Brokers Digitales */}
                  </h2>
                  <p className="lead">
                    Somos los mejores brokers digitales, mediante nuestra
                    metodologia <span className="color-secondary">FSELL-PRO</span> conseguimos vender pisos en tiempo record.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <div className="pricing-img mt-3 mb-4">
                    <img
                       src="icons/ico-triste.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                     {/* <span className="ti-thumb-down img-fluid"></span> */}
                  </div>

                  <h5 className="mb-2">Sin Metodo</h5>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list align-items-start" >
                      <li>Mas de un año con tu piso en venta</li>
                      <li>Pisos con poca exposicion</li>
                      <li>Pisos con malas fotos</li>
                      <li>Compradores de mala calidad</li>
                      <li>Ofertas no tentadoras</li>
                      <li>Sin estrategia de venta</li>
                      <li>Sin aprovechar el poder de la IA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price2 single-pricing-pack p-5 pricing-backgrond">
                
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      // src="assets/img/standard.svg"
                      src="icons/ico-feliz.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-2">Con Metodo</h5>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">
                              Metodo <span className="color-secondary">FSELL-PRO</span>, para vender tu propiedad en
                              forma acelerada con la mas moderna tecnologia de
                              intelegencia artificial.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">Proteges tu patrimonio.</p>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">
                              Gestion integral de banca, notarios y servicios
                              publicos.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">
                              Calendario de visitas unicamente con interesados
                              reales.{" "}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">
                              Decoracion y limpieza de tu piso.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">
                              Sesion fotográfica profesional estilo Airbnb, Planos, Recorrido 3D.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="">
                        <div className="d-flex align-items-start">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 pricing-card-color">
                              Estrategias de marketing con neuroventas.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p className="mb-2">
                Si necesitas conocer mas?
                <span href="#"className="color-secondary" style={{ cursor: 'pointer' }}onClick={handleOpen}>
                  {" "}
                  <span className="color-secondary">Escribinos</span>{" "}
                </span>
              </p>
            </div>
          </div>
        </section>
        <ResponsiveDialog
          open={open}
          handleClose={handleClose}
          titleButton={"Enviar"}
          title={"Mas informacion"}
          subTitle={null}
        />
      </div>
    </>
  );
}
