// import React, { useState } from "react";
// import ModalVideo from "react-modal-video";
// import { Link } from "react-router-dom";

export default function PromoAgenteInmobiliario() {
  // const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        id="download"
        className="ptb-100 background-img"
        style={{
          background:
            "url('assets/img/video-bg.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="about-content-left text-center align-items-center">
                <div className="single-team-member position-relative align-items-center">
                  <div className="">
                    <img
                      src="assets/team/team-damian.jpg"
                      alt="Team Members"
                      className="                 
                      rounded-circle"
                      style={{ height: "250px", width: "250px" }}
                    />
                  </div>
                  <h2 className="text-white">Damian Frieri</h2>
                  <h4 className="" style={{ color: "#FE9B40" }}>
                    Ingeniero Inmobiliario
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content-right section-heading text-center">
                <div
                  className="row equal"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridTemplateRows: "1fr",
                    gridColumnGap: "25px",
                    gridRowGap: "0px",
                    margin: "0 15px",
                  }}
                >
                  <img
                    loading="lazy"
                    decoding="async"
                    width="385"
                    height="135"
                    src="assets/img/logos/CIA-BLANCO-8.webp"
                    class="img-fluid"
                    alt=""
                  ></img>
                  <img
                    loading="lazy"
                    width="385"
                    height="135"
                    src="assets/img/logos/CUCICBA-BLANCO-8.webp"
                    class="img-fluid"
                    alt=""
                  ></img>
                  <img
                    loading="lazy"
                    width="385"
                    height="135"
                    src="assets/img/logos/ICI-BLANCO-8.webp"
                    class="img-fluid"
                    alt=""
                  ></img>
                  <img
                    loading="lazy"
                    width="385"
                    height="135"
                    src="assets/img/logos/LCNI-BLANCO-8.webp"
                    class="img-fluid"
                    alt=""
                  ></img>
                </div>
                <h5 className="mt-4 text-white">
                  En la inmobiliaria estamos comprometidos en vender tu
                  propiedad en <span style={{ color: "#FE9B40" }}>90 dias</span>{" "}
                  y al mejor precio de mercado. Para lograrlo utilizamos el
                  <span style={{ color: "#FE9B40" }}> Método FSELL-PRO </span> que fue creado con este objetivo.
                </h5>
                <h5 className="mt-4 text-white">
                  Ademas aplicamos de Marketing Digital, Inteligencia Artificial
                  y Neuroventas equilibrando la innovación tecnológica con las
                  relaciones personales, para cumplir sueños inmobiliarios.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

{
  /* <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="mt-4 text-center">
                <div className="col-md-6">
                  <div className="about-content-left">
                    <div className="single-team-member position-relative">
                      <div className="team-image">
                        <img
                          src="assets/img/team-4.jpg"
                          alt="Team Members"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <h2 className="text-white">Damian Frieri</h2>
                      <h4 className="text-yellow">Ingeniero Inmobiliario</h4>
                    </div>
                  </div>
                </div>
                <div className="about-content-right section-heading">
                  <div
                    className="row equal"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gridTemplateRows: "1fr",
                      gridColumnGap: "25px",
                      gridRowGap: "0px",
                    }}
                  >
                    <img
                      loading="lazy"
                      decoding="async"
                      width="385"
                      height="135"
                      src="assets/img/logos/CIA-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                    <img
                      loading="lazy"
                      width="385"
                      height="135"
                      src="assets/img/logos/CUCICBA-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                    <img
                      loading="lazy"
                      width="385"
                      height="135"
                      src="assets/img/logos/ICI-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                    <img
                      loading="lazy"
                      width="385"
                      height="135"
                      src="assets/img/logos/LCNI-BLANCO-8.webp"
                      class="img-fluid"
                      alt=""
                    ></img>
                  </div>
                  <h5 className="mt-4 text-white">
                    En la inmobiliaria estamos comprometidos en vender tu
                    propiedad en 2 meses y al mejor precio de mercado. Para
                    lograrlo utilizamos el "Método por Etapas" que fue creado
                    con este objetivo.
                  </h5>
                  <h5 className="mt-4 text-white">
                    Ademas aplicamos de Marketing Digital, Inteligencia
                    Artificial y Neuroventas equilibrando la innovación
                    tecnológica con las relaciones personales, para cumplir
                    sueños inmobiliarios.
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId="9No-FiEInLA"
            onClose={() => setOpen(false)}
          />
       </div> */
}
